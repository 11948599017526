<template>
    <div class="login-container">
      <div class="row login-card">
        <div class="col-md-6 login-card-col-1">
          <div class="card-content" :class="{ active: state.activeIndex === 0 }">
            <img
              src="../assets/img/logo-financier.jpeg"
              style="width:250px; margin:0 auto"
              class="mb-5"
            />
            <Form @submit="handleLogin" :validation-schema="schema">
              <div class="form-group">
                <label for="username">Email</label>
                <Field v-model="username" name="username" type="text" class="form-control" style="text-transform: lowercase" @input="removeSpaces" />
                <ErrorMessage name="username" class="error-feedback" />
              </div>
              <div class="form-group">
                <label for="password">Senha</label>
                <div class="field-has-addons">
                    <div class="field">
                      <Field v-if="showPassword" name="password" type="text" class="form-control" />
                      <Field v-else name="password" type="password" class="form-control" />
                    </div>
                    <div class="field-addon" @click="toggleShow">
                        <i class="bi" :class="{ 'bi-eye-slash-fill': showPassword, 'bi-eye-fill': !showPassword }"></i>
                    </div>
                </div>
                <ErrorMessage name="password" class="error-feedback" />
              </div>

              <div class="form-group">
                <button class="btn btn-primary btn-block btn-login" :disabled="loading">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <span>Acessar</span>
                </button>
              </div>

              <div class="form-group">
                <div v-if="message" class="alert alert-danger" role="alert">
                  {{ message }}
                </div>
              </div>
            </Form>
            <div class="px-1 pt-0 text-center px-lg-2" @click="setActive(1)">
              <p class="mx-auto mb-4 text-sm" style="
                        font-size: 13px;
                        text-decoration: underline;
                        cursor:pointer
                    ">
                Esqueci minha senha.
              </p>
            </div>
          </div>
          <div class="card-content" :class="{ active: state.activeIndex === 1}">
            <img
                src="../assets/img/logo-financier.jpeg"
                style="width:250px; margin:0 auto"
                class="mb-5"
              />
            <div class="px-1 pt-0 px-lg-2" @click="setActive(0)">
              <p class="mx-auto mb-4 text-sm font-weight-bold" style="color:rgb(208 141 88); text-decoration: underline; cursor: pointer">
                Voltar para o login
              </p>
            </div>
            <!--<Form @submit="recoveryPassword">-->
            <Form @submit="recoveryPassword">
              <div class="form-group">
                <label for="password">Informe seu email de cadastro</label>
                <Field name="recpassword" type="email" v-model="emailForRecovery" class="form-control" style="text-transform: lowercase" />
                <ErrorMessage name="recpassword" class="error-feedback" />
              </div>

              <div class="form-group">
                <button class="btn btn-primary btn-block btn-login" :disabled="loading">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <span>Recuperar senha</span>
                </button>
              </div>

              <div class="form-group">
                <div v-if="messagepwd" class="alert alert-success" role="alert">
                  {{ messagepwd }}
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div class="col-md-6 login-card-col-2">
          <img
              src="@/assets/img/image-login.png"
              style="max-width: 100%"
            />
        </div>
      </div>
    </div>
      
  
</template>

<script>
import { reactive } from 'vue'
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import axios from 'axios';

export default {
  setup() {
    
    const state = reactive({
      activeIndex: 0
    })

    const setActive = (index) => {
      state.activeIndex = index
    }

    return {
      state,
      setActive
    }
  },
  name: "Login",
  
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Este campo é obrigatório!"),
      password: yup.string().required("Este campo é obrigatório!"),
    });

    return {
      loading: false,
      message: "",
      messagepwd: "",
      schema,
      emailForRecovery: "",
      showPassword: false,
      username: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    
  },
  created() {
    if (this.loggedIn) {
      if(this.$store.state.auth.user.roles == 1 || this.$store.state.auth.user.roles == 4) {
        this.$router.push("/admin");
      }
      if(this.$store.state.auth.user.roles == 2) {
        this.$router.push("/dashboard");
      }
      if(this.$store.state.auth.user.roles == 3) {
        this.$router.push("/user");
      }
    }
  },
  methods: {
    removeSpaces() {
      // Remove leading and trailing whitespace from the username
      this.username = this.username.trim();
    },
    async getBusinessCredentials() {
        try {
            let cEmail = this.$store.state.auth.user.email;
            // console.log("teste 1", cEmail);
            let res = await axios.post(
                `${process.env.VUE_APP_API_URL}auth/businesscredentials`,
                {
                    email: cEmail,
                }
            );
            let data = res.data;
            localStorage.setItem('bid', JSON.stringify(data));
            // console.log("teste", data);
        } catch (err) {
            console.log(err);
        }
    },

    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          let cUser = this.$store.state.auth.user.roles[0];
          // console.log("STOREEEE",cUser);
      
          if(cUser === "1") {
              this.$router.push("/admin");
          }

          if(cUser === "2") {
            this.getBusinessCredentials();
            this.$router.push("/dashboard");
          }

          if(cUser === "3") {
            this.getBusinessCredentials();
            this.$router.push("/user");
          }

          if(cUser === "4") {
            this.getBusinessCredentials();
            this.$router.push("/admin");
          }

          // if(this.$store.state.auth.user.firstLogin) {
          //   this.$router.push("/meus-dados");
          // }

        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    async recoveryPassword() {
        try {
            await axios.post(
                //`http://localhost:3000/api/lead/update/${this.$route.params.id}`,
                //`http://localhost:3003/api/lead/update/${this.$route.params.id}`,
                //`http://homolog.ganbei.io/api/lead/update/${this.$route.params.id}`,
                // how to force lowercase at this.emailForRecovery?
                `${process.env.VUE_APP_API_URL}test/forgotpwd/`,
                {
                    email: this.emailForRecovery.toLowerCase(),
                }
            );
            this.messagepwd = "Solicitação enviada. Confira seu email e siga as instruções para redefinir sua senha.";
            this.emailForRecovery = "";
            // this.productPrice = "";
            // this.$router.push("/");
        } catch (err) {
            this.loading = false;
          this.messagepwd =
            (err.response &&
              err.response.data &&
              err.response.data.message) ||
            err.message ||
            err.toString();
        }
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    }
  },
};
</script>

<style scoped lang="scss">
label {
  display: block;
  margin-top: 10px;
}
.login-container {
  background-image: linear-gradient(180deg, #dfad73, #ebbb8b);
  padding: 40px 0;
  height: 100%;
  width: 100%;
}
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
.field-has-addons {
  display: flex;
  .field {
    flex-grow: 1
  }
  .field-addon {
    padding: 0 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-left: 0;
    color: #737373;
    border-radius: 0 5px 5px 0;
  }
}
.card-container .card-content {
  display: none;
}

.card-container .card-content.active {
  display: block;
}
.login-card {
  max-width: 900px;
  margin: 0 auto;
  padding: 0;
  background: #fff;
  border-radius: 10px;
}
.login-card .card-content {
  display: none;
}

.login-card .card-content.active {
  display: block;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.login-card-col-1 {
  padding: 40px 100px 0px;background: #fff;margin-bottom: 50px; border-radius: 10px 0 0 10px;
}

.login-card-col-2 {
  padding-right: 0px;
}

.error-feedback {
  color: red;
}
@media only screen and (max-width: 600px) {
  .login-card-col-1 {
    padding: 20px 10px 0px;
  }
  .login-card-col-2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .login-container {
    padding: 40px 20px;
  }
}
</style>

<template>
  <notifications />
  <div class="container-full d-flex">
    <div :class="[{'d-none' : clientListMobileBehavior}, 'd-md-block']" v-if="currentUser" :style="location.path === '/chat' ? 'display: none !important' : ''">
      <sidebar-menu />
    </div>
    <div style="flex-grow: 1;">
      <div v-if="showMobileMenu" class="mobile-menu">
        <div class="d-flex justify-content-between align-items-center p-2">
          <div>
            <i class="bi bi-person-circle mr-2"></i> {{ currentUser.username }}
          </div>
          <button style="background: transparent;border: 0;" @click.prevent="hideMobileMenu()">
            <i class='bx bxs-x-square' style="font-size: 25px;"></i>
          </button>
        </div>
        <ul>
          <li v-if="showAdminBoard">
            <router-link @click="hideMobileMenu()" :to="{ name: 'admin' }">
              <i class="bx bxs-home"></i> Painel
            </router-link>
          </li>
          <li v-if="showModeratorBoard">
            <router-link @click="hideMobileMenu()" :to="{ name: 'moderator' }">
              <i class="bx bxs-home"></i> Painel
            </router-link>
          </li>
          <li v-if="showAdminBoard">
            <router-link @click="hideMobileMenu()" :to="{ name: 'solicitations' }">
              <i class="bx bxs-user-check"></i> Solicitações
            </router-link>
          </li>
          <li v-if="!showAdminBoard">
            <router-link @click="hideMobileMenu()" :to="{ name: 'chat' }">
              <i class="bx bx-chat"></i> Atendimentos
            </router-link>
          </li>
          <li v-if="showModeratorBoard || showAdminBoard">
            <router-link @click="hideMobileMenu()" :to="{ name: 'sellers' }">
              <i class="bx bx-user"></i> Usuários
            </router-link>
          </li>
          <li v-if="showModeratorBoard || showAdminBoard">
            <router-link @click="hideMobileMenu()" :to="{ name: 'financeiro' }">
              <i class="bx bx-money"></i> Financeiro
            </router-link>
          </li>
          <li>
            <a @click.prevent="logOut">
              <i class="bi bi-box-arrow-left"></i> Sair
            </a>
          </li>
        </ul>
      </div>
      <template :class="[{'d-none' : clientListMobileBehavior}]">
        <div class="alert-msgs-mobile">
          <router-link :to="{ name: 'solicitations' }">
              <span :style="sum.length > 0 && showAdminBoard ? 'color:#ae5ee3; text-decoration: underline' : 'color: transparent;'">
                Existe<span v-if="sum.length > 1">m</span>
                {{sum.length}} Solicitaç<span v-if="sum.length = 1">ão</span><span v-if="sum.length > 1">ões</span> 
                de Cadastro Aguardando Avaliação. 
              </span>
          </router-link>
        </div>
      </template>
      <div v-if="currentUser" :class="[location.path === '/chat' ? 'hide-topbar' : '', 'top-header']">
        <div class="first-block" :class="[{'d-flex' : clientListMobileBehavior}]">
              <span class="d-none d-md-block">
                <img
                  src="@/assets/img/logo-financier.jpeg"
                  class="logo-header"
                >
              </span>
              <div class="mobile-logo-container d-none d-sm-block d-md-none d-flex justify-content-between align-items-center" :style="clientListMobileBehavior ? 'display: none' : ''">
                <button @click.prevent="displayMobileMenu()" :style="location.path === '/chat' ? 'display: none' : ''" class="btn-transparent">
                  <i class="bi bi-list"></i>
                </button>
                <a href="https://financier.ganbei.io">
                  <img
                    src="@/assets/img/logo-ganbei.jpg"
                    class="menu-iconlogo-mobile"
                  >
                </a>
                <template v-if="showAdminBoard">
                  <router-link @click="hideMobileMenu()"  :to="{ name: 'profileadm' }">
                    <i class="bi bi-person-badge"></i>
                  </router-link>
                </template>
                <template v-else>
                  <router-link @click="hideMobileMenu()" :to="{ name: 'profile' }">
                    <i class="bi bi-person-badge"></i>
                  </router-link>
                </template>
              </div>
              <template :class="[{'d-none' : clientListMobileBehavior}]">
                <span class="alert-msgs-mobile" v-if="sum.length > 0 || processingSolicitations.length > 0">
                  <router-link :to="{ name: 'solicitations' }" >
                  <span  v-if="sum.length > 0 && showAdminBoard">
                    Solicitações Pendentes: {{sum.length}}
                  </span>
                  <span v-if="processingSolicitations.length > 0">
                    solicitações em andamento.
                    <span>
                      {{processingSolicitations.length}}
                    </span> 
                  </span>
                  </router-link>
                </span>
              </template>
              
              
        </div>
        <template v-if="!clientListMobileBehavior">
          <div class="d-none d-md-block">
            <div v-if="showAdminBoard" class="btn-group nav-dropdown dropdown-alert mr-3">
              <button type="button" class="dropdown-toggle no-arrow" data-toggle="dropdown" data-display="static" aria-expanded="false">
                <i class="bi bi-bell"></i>
              </button>
              <div v-if="pendingSolicitatios.length > 0" class="alert-pushup">
                {{pendingSolicitatios.length}}
              </div>
              <div class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-item d-flex" v-for="item in pendingSolicitatios" :key="item.contact_status">
                  <router-link :to="{ name: 'solicitation-edit', params: { id: item.id } }" >
                    <span class="mr-2">{{item.name}} fez uma solicitação de cadastro.</span>
                    <span>( contactar por {{item.contact_preference}} {{item.contact_time}} )</span>
                  </router-link>
                </div>
              </div>
            </div>
            
            <div class="btn-group nav-dropdown">
              <button type="button" class="dropdown-toggle d-flex align-items-center" data-toggle="dropdown" aria-expanded="false">
                <div class="d-flex align-items-center">
                  <i class="bi bi-person-circle"></i> 
                  <div class="current-user-info mx-2">
                    <span class="current-user-name">{{ currentUser.username }}</span>
                    <small>{{roleName}}</small>
                  </div>
                </div>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <router-link v-if="showAdminBoard" :to="{ name: 'profileadm' }" class="dropdown-item">
                  <i class="bi bi-person-badge"></i> Meus dados
                </router-link>
                <!--
                <router-link v-if="showAdminBoard" :to="{ name: 'list-admins' }" class="dropdown-item">
                  <i class="bi bi-person-badge"></i> Administradores
                </router-link>
                -->
                <router-link v-else :to="{ name: 'profile' }" class="dropdown-item">
                  <i class="bi bi-person-badge"></i> Meus dados
                </router-link>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" @click.prevent="logOut">
                  <i class="bi bi-box-arrow-left"></i> Sair
                </a>
              </div>
            </div>
          </div>
        </template>
      </div>
      <router-view />
    </div>
  </div>
</template>
<script setup>
  //import BaseHeader from './components/organisms/BaseHeader.vue';
  //import ClientDetail from './components/organisms/ClientDetail.vue';
  //import ChatVisibility from "./components/atoms/ChatVisibility.vue";
  import SidebarMenu from "./components/SidebarMenu.vue";
  import LeadService from "./services/lead.service";
  import { onMounted, ref, watch, watchEffect } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { RouterLink, RouterView } from 'vue-router';

  const location = useRoute();
  //const router = useRouter();
  const clientListMobileBehavior = ref(false);
  const minWidthQuery = window.matchMedia('(max-width: 600px)');
  const showMobileMenu = ref(false);

  const checkIfIsMobile = () => {
    clientListMobileBehavior.value = true;
  };

  function displayMobileMenu () {
    // console.log("MENU")
    showMobileMenu.value = true
  }
  function hideMobileMenu () {
    // console.log("MENU")
    showMobileMenu.value = false
  }

  watchEffect(() => {
    if (minWidthQuery.matches) {
      checkIfIsMobile();
    }
  });
  
  const isChat = ref(false);
  onMounted(async () => {
    // console.log("chat", isChat.value)
  });

  watch(isChat, (newValue) => {
    // console.log("chat", isChat.value)
  },
  {
    immediate: true,
  });
  watch(isChat, (oldValue) => {
    // console.log("chat", isChat.value)
  },
  {
    immediate: true,
  });
</script>
<script>
import { provide } from 'vue';

export default {
  components: {
    SidebarMenu,
    //ChatVisibility
  },
  data() {
    return {
      //isChat: false,
      pendingSolicitatios: [],
      processingSolicitations: [],
      teste: [],
      roleName: '',
    };
  },
  computed: {
    currentUser() {
      this.roleName = this.$store.state.auth.user?.roles;
      if(this.roleName == 1) this.roleName = "Administrador Ganbei";
      if(this.roleName == 2) this.roleName = "Administrador";
      if(this.roleName == 3) this.roleName = "Atendente";
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if ((this.currentUser && this.currentUser.roles == 1) || (this.currentUser && this.currentUser.roles == 4)) {
        return true;
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles == 2) {
        return true;
      }

      return false;
    },
    showUserBoard() {
      if (this.currentUser && this.currentUser.roles == 3) {
        return true;
      }

      return false;
    },
    sum() {
      return this.teste;
    },
    // retrieveLeads() {
    //   LeadService.getLeads()
    //     .then(response => {
    //       //this.pendingSolicitatios = response.data;
    //       response.data.forEach(item => {
    //         if (item.contact_status == 1) {
    //           this.pendingSolicitatios.push(item)
    //         }
    //         if (item.contact_status == 4) {
    //           this.processingSolicitations.push(item)
    //         }
    //       });
    //       //this.pendingSolicitatios = response.data;      
    //       console.log("METODO RETRIEVE LEADS ROOT",response.data);
    //     })
    //     .catch(e => {
    //       console.log(e);
    //     });
    // },
  },
  mounted() {
    //console.log('route.path', window.location.pathname);
    this.retrieveLeads();
    //console.log("AAAAAAAAAAAAAUUUTTTHHHH", this.$store.state.auth);
    // setTimeout(() => {
    //     if (this.pendingSolicitatios.length > 0) {
    //       this.teste = this.pendingSolicitatios
    //     } 
    //   }, 3000);
  },
  created () {
    // setInterval(() => {
    //   this.refreshLeads();
    // }, 5000)
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    retrieveLeads() {
      this.pendingSolicitatios.length = 0;
      LeadService.getLeads()
        .then(response => {
          //this.pendingSolicitatios = response.data;
          response.data.forEach(item => {
            if (item.contact_status == 1) {
              this.pendingSolicitatios.push(item)
            }
            if (item.contact_status == 4) {
              this.processingSolicitations.push(item)
            }
          });
          //this.pendingSolicitatios = response.data;      
          //console.log("METODO REFRESH LEADS ROOT",response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },
    // refreshLeads() {
    //   this.teste.length = 0;
      
      
    //     LeadService.getLeads()
    //     .then(response => {
    //       //this.pendingSolicitatios = response.data;
    //       response.data.forEach(item => {
    //         if (item.contact_status == 1) {
    //           this.teste.push(item)
    //         }
    //       });
    //       //this.pendingSolicitatios = response.data;      
    //       //console.log("METODO REFRESH LEADS ROOT",response.data);
    //     })
    //     //console.log("Delayed action executed", this.teste);
      
      
      
    //   //this.pendingSolicitatios.length = 0;
    //   //this.retrieveLeads();
    //   //console.log("REFRESCADO", this.pendingSolicitatios);
    //   //console.log('Root Function Called');
    // },
    // provideRefreshLeads() {
    //   // Use the provide method to pass a reference to the root instance down to the child component
    //   provide('refreshLeads', this.refreshLeads);
    // }
  }
};
</script>
<style lang="scss">
@import "./assets/css/style.scss";

.app {
  position: relative;
}
.logo-header {
  width: 140px;
}
.hide-topbar {
    display: none !important;
  }
.top-header {
  width: 100%;
  height: 51px;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
  background: #fff;
  position: relative;
  align-items: center;
  .dropdown-toggle {
    i.bi {
      font-size: 27px;
    }
  }
  .dropdown-toggle::after {
    border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-bottom: 0;
    border-left: 0.5em solid transparent;
  }
  .current-user-info {
    text-align: left;
    display: flex;
    flex-direction: column;
    .current-user-name {
      display: block;
      font-weight: 500;
      line-height: 10px;
      margin-top: 7px;
    }
    small {
      color: #afafaf;
    }
  }
  .first-block {
    font-size: 14px;
    font-weight: 500;
    color: #8e4abc;
  }
}
.top-header .alert-msgs{
  text-decoration:underline;
  padding-left:5px;
  display: block;
}
.top-header .alert-msgs a{
  color: rgb(142, 74, 188);
  font-size: 12px;
}
.alert-msgs-mobile{
  margin-top: 60px;
  display: block;
  text-align: center;
  
}
.alert-msgs-mobile a{
  color: #fff;
  font-size: 12px;
}
.top-header::before {
  content: "";
  position: absolute;
  background-color: transparent;
  top: 51px;
  height: 54px;
  width: 25px;
  border-top-left-radius: 25px;
  box-shadow: 0 -25px 0 0 #ffffff;
  left: 0px;
}
.dropdown-toggle {
  &.no-arrow::after {
   display:none !important; 
  }
}
.dropdown-alert {
  .dropdown-item {
    border-bottom: 1px solid #ccc;
    padding: 10px 26px;
    font-size: 13px;
    a {
      color: #1f1e1c;
    }
  }
}
.alert-pushup {
  width: 19px;
    height: 19px;
    border-radius: 100%;
    background: red;
    color: #fff;
    font-size: 12px;
    padding: 5px;
    line-height: 10px;
}
@media only screen and (max-width: 600px) {
  .hide-topbar {
    display: flex;
  }
  .top-header {
    padding: 5px 10px;
    position: fixed;
    top: 0;
    z-index: 100;
    .first-block {
      margin-left: 0px;
      width: 100%;
      .mobile-logo-container {
        width: 100%;
      }
      .bi {
        color: #ae5ee3;
      }
      .bi-list {
        font-size: 25px;
      }
    }
  }
  .top-header::before {
    display: none;
  }
  .menu-iconlogo-mobile {
    width: 125px;
    margin-right: 10px;
  }
}
.mobile-menu {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100vw;
  background: #f7f9f7;
  height: 200vh;
  li {
    padding: 15px 15px 15px 40px;
    border-bottom: 1px solid #d2d1d1;
    .bx, .bi {
      font-size: 25px;
    }
    a {
      color: #ab59eb;
    }
  }
}
.btn-transparent {
  background: transparent;
  border: 0
}
</style>

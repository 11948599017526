<template>
  <div
    class="sidebar"
    :class="isOpened ? 'open' : ''"
    :style="cssVars"
  >
    <div
      class="logo-details"
      style="margin: 6px 14px 0 14px;"
    >
      <i v-if="!isOpened" @click="isOpened = !isOpened" class='bx bx-menu'></i>
      <!-- <img
        
        src="@/assets/img/logo-ganbei.jpg"
        alt="menu-logo"
        class="menu-logo icon"
      > -->
      <!--
      <i
        v-else
        class="bx icon"
        :class="menuIcon"
      />
      
      <div class="logo_name">
        {{ menuTitle }}
      </div>
      -->
      <i
        v-if="isOpened"
        class="bx bx-menu-alt-left"
        id="btn"
        @click="isOpened = !isOpened"
      />
    </div>

    <div style="display: flex ; flex-direction:column; justify-content: space-between; flex-grow: 1; max-height: calc(100% - 60px); ">
      <div
        id="my-scroll"
        style="margin: 6px 0 0 14px;"
      >
        <ul
          class="nav-list admin"
          :class="{moderator: showModeratorBoard}"
          style="overflow: visible;"
        >
          <li
            v-if="isSearch"
            @click="isOpened = true"
          >
            <i class="bx bx-search" />
            <input
              type="text"
              :placeholder="searchPlaceholder"
              @input="$emit('search-input-emit', $event.target.value)"
            >
            <span class="tooltip">{{ searchTooltip }}</span>
          </li>

          <span
            v-for="(menuItem, index) in menuItems"
            :key="index"
          >
            <li v-if="menuItem.role == 'superadmin' && isSuperAdmin">
            
              <router-link :to="{ name: `${menuItem.link}` }">
                <i
                  v-if="menuItem.icon !== ''"
                  class="bx"
                  :class="menuItem.icon || 'bx-square-rounded'"
                />
                <img
                  v-else
                  :src="menuItem.icon_image"
                >
                <span class="links_name">{{ menuItem.name }}</span>
              </router-link>
            
              <span class="tooltip" :class="menuItem.link">{{ menuItem.tooltip || menuItem.name }}</span>
            </li>
            <li v-if="menuItem.role == 'admin' && isAdmin">
            
              <router-link :to="{ name: `${menuItem.link}` }">
                <i
                  v-if="menuItem.icon !== ''"
                  class="bx"
                  :class="menuItem.icon || 'bx-square-rounded'"
                />
                <img
                  v-else
                  :src="menuItem.icon_image"
                >
                <span class="links_name">{{ menuItem.name }}</span>
              </router-link>
            
              <span class="tooltip" :class="menuItem.link">{{ menuItem.tooltip || menuItem.name }}</span>
            </li>
            <li v-if="menuItem.role == 'moderator' && showModeratorBoard">
            
              <router-link :to="{ name: `${menuItem.link}` }">
                <i
                  class="bx"
                  :class="menuItem.icon || 'bx-square-rounded'"
                />
                <span class="links_name">{{ menuItem.name }}</span></router-link>
            
              
              <span class="tooltip" :class="menuItem.link">{{ menuItem.tooltip || menuItem.name }}</span>
            </li>
            <li v-if="menuItem.role == 'seller' && showUserBoard">
            
              <router-link :to="{ name: `${menuItem.link}` }">
                <i
                  class="bx"
                  :class="menuItem.icon || 'bx-square-rounded'"
                />
                <span class="links_name">{{ menuItem.name }}</span>
              </router-link>
            
              <span class="tooltip">{{ menuItem.tooltip || menuItem.name }}</span>
            </li>
          </span>
        </ul>
      </div>
      
      
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SidebarMenu',
    props: {
      //! Menu settings
      isMenuOpen: {
        type: Boolean,
        default: false,
      },
      menuTitle: {
        type: String,
        default: 'Ganbei',
      },
      menuLogo: {
        type: String,
        default: '@/assets/img/logo-ganbei.jpg',
      },
      menuIcon: {
        type: String,
        default: 'bxl-c-plus-plus',
      },
      isPaddingLeft: {
        type: Boolean,
        default: true,
      },
       menuOpenedPaddingLeftBody: {
        type: String,
        default: '210px'
      },
      menuClosedPaddingLeftBody: {
        type: String,
        default: '78px'
      },

      //! Menu items
      menuItems: {
        type: Array,
        default: () => [
          {
            link: 'admin',
            name: 'Dashboard',
            tooltip: 'Dashboard',
            icon: 'bx-grid-alt',
            icon_image: '',
            role: 'superadmin'
          },
          // {
          //   link: 'solicitations',
          //   name: 'Solicitações',
          //   tooltip: 'Solicitações',
          //   icon: 'bxs-user-check',
          //   icon_image: require('@/assets/icon/solicitation-menu.png'),
          //   role: 'superadmin',
          // },
          // {
          //   link: 'companies',
          //   name: 'Corretoras',
          //   tooltip: 'Corretoras',
          //   icon: 'bx-building-house',
          //   icon_image: '',
          //   role: 'superadmin',
          // },
          {
            link: 'sellers',
            name: 'Usuários',
            tooltip: 'Usuários',
            icon: 'bx-user',
            icon_image: '',
            role: 'superadmin',
          },
          // {
          //   link: 'financeiro',
          //   name: 'Financeiro',
          //   tooltip: 'Financeiro',
          //   icon: 'bx-money',
          //   icon_image: '',
          //   role: 'superadmin',
          // },
          // {
          //   link: 'creditos',
          //   name: 'Créditos',
          //   tooltip: 'Créditos',
          //   icon: 'bx-coin-stack',
          //   icon_image: '',
          //   role: 'superadmin',
          // },
          {
            link: 'relatorios',
            name: 'Relatórios',
            tooltip: 'Relatórios',
            icon: 'bxs-report',
            icon_image: '',
            role: 'superadmin',
          },
          {
            link: 'list-admins',
            name: 'Usuarios',
            tooltip: 'Usuários Administrativos',
            icon: 'bi-person-fill-gear',
            icon_image: '',
            role: 'superadmin',
          },
          {
            link: 'admin',
            name: 'Dashboard',
            tooltip: 'Dashboard',
            icon: 'bx-grid-alt',
            icon_image: '',
            role: 'admin'
          },
          // {
          //   link: 'solicitations',
          //   name: 'Solicitações',
          //   tooltip: 'Solicitações',
          //   icon: 'bxs-user-check',
          //   icon_image: require('@/assets/icon/solicitation-menu.png'),
          //   role: 'admin',
          // },
          // {
          //   link: 'companies',
          //   name: 'Corretoras',
          //   tooltip: 'Corretoras',
          //   icon: 'bx-building-house',
          //   icon_image: '',
          //   role: 'admin',
          // },
          {
            link: 'sellers',
            name: 'Usuários',
            tooltip: 'Usuários',
            icon: 'bx-user',
            icon_image: '',
            role: 'admin',
          },
          // {
          //   link: 'financeiro',
          //   name: 'Financeiro',
          //   tooltip: 'Financeiro',
          //   icon: 'bx-money',
          //   icon_image: '',
          //   role: 'admin',
          // },
          // {
          //   link: 'creditos',
          //   name: 'Créditos',
          //   tooltip: 'Créditos',
          //   icon: 'bx-coin-stack',
          //   icon_image: '',
          //   role: 'admin',
          // },
          {
            link: 'relatorios',
            name: 'Relatórios',
            tooltip: 'Relatórios',
            icon: 'bxs-report',
            icon_image: '',
            role: 'admin',
          },
          {
            link: 'moderator',
            name: 'Dashboard',
            tooltip: 'Dashboard',
            icon: 'bx-grid-alt',
            icon_image: '',
            role: 'moderator'
          },
          {
            link: 'chat',
            name: 'Atendimento',
            tooltip: 'Atendimento',
            icon_image: '',
            icon: 'bx-chat',
            role: 'moderator',
          },
          {
            link: 'sellers',
            name: 'Usuários',
            tooltip: 'Usuários',
            icon: 'bx-user',
            icon_image: '',
            role: 'moderator',
          },
          // {
          //   link: 'creditos',
          //   name: 'Créditos',
          //   tooltip: 'Créditos',
          //   icon: 'bx-coin-stack',
          //   icon_image: '',
          //   role: 'moderator',
          // },
          // {
          //   link: 'financeiro',
          //   name: 'Financeiro',
          //   tooltip: 'Financeiro',
          //   icon: 'bx-money',
          //   icon_image: '',
          //   role: 'moderator',
          // },
          {
            link: 'relatorios',
            name: 'Relatórios',
            tooltip: 'Relatórios',
            icon: 'bxs-report',
            icon_image: '',
            role: 'moderator',
          },
          {
            link: 'user',
            name: 'Dashboard',
            tooltip: 'Dashboard',
            icon: 'bx-grid-alt',
            icon_image: '',
            role: 'seller',
          },
          {
            link: 'chat',
            name: 'Atendimento',
            tooltip: 'Atendimento',
            icon_image: '',
            icon: 'bx-chat',
            role: 'seller',
          },
          // {
          //   link: 'creditos',
          //   name: 'Créditos',
          //   tooltip: 'Créditos',
          //   icon: 'bx-coin-stack',
          //   role: 'seller',
          // },
          // {
          //   link: '#',
          //   name: 'Analytics',
          //   tooltip: 'Analytics',
          //   icon: 'bx-pie-chart-alt-2',
          // },
          // {
          //   link: '#',
          //   name: 'File Manager',
          //   tooltip: 'Files',
          //   icon: 'bx-folder',
          // },
          // {
          //   link: '#',
          //   name: 'Order',
          //   tooltip: 'Order',
          //   icon: 'bx-cart-alt',
          // },
          // {
          //   link: '#',
          //   name: 'Saved',
          //   tooltip: 'Saved',
          //   icon: 'bx-heart',
          // },
          // {
          //   link: '#',
          //   name: 'Setting',
          //   tooltip: 'Setting',
          //   icon: 'bx-cog',
          // },
        ],
      },

      //! Search
      isSearch: {
        type: Boolean,
        default: false,
      },
      searchPlaceholder: {
        type: String,
        default: 'Search...',
      },
      searchTooltip: {
        type: String,
        default: 'Search',
      },

      //! Profile detailes
      profileImg: {
        type: String,
        default: require('../assets/img/logo-ganbei.jpg'),
      },
      profileName: {
        type: String,
        default: 'Fayzullo Saidakbarov',
      },
      profileRole: {
        type: String,
        default: 'Frontend vue developer',
      },
      isExitButton: {
        type: Boolean,
        default: true,
      },
      isLoggedIn: {
        type: Boolean,
        default: true,
      },

      //! Styles
      bgColor: {
        type: String,
        default: '#fff',
      },
      secondaryColor: {
        type: String,
        default: '#1d1b31',
      },
      homeSectionColor: {
        type: String,
        default: '#e4e9f7',
      },
      logoTitleColor: {
        type: String,
        default: '#fff',
      },
      iconsColor: {
        type: String,
        default: '#fff',
      },
      itemsTooltipColor: {
        type: String,
        default: '#e4e9f7',
      },
      searchInputTextColor: {
        type: String,
        default: '#fff',
      },
      menuItemsHoverColor: {
        type: String,
        default: '#fff6ee',
      },
      menuItemsTextColor: {
        type: String,
        default: '#fff',
      },
      menuFooterTextColor: {
        type: String,
        default: '#fff',
      },
    },
    data() {
      return {
        isOpened: false
      }
    },
    mounted() {
      this.isOpened = this.isMenuOpen;
    },
    computed: {
      cssVars() {
        return {
          // '--padding-left-body': this.isOpened ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody,
          '--bg-color': this.bgColor,
          '--secondary-color': this.secondaryColor,
          '--home-section-color': this.homeSectionColor,
          '--logo-title-color': this.logoTitleColor,
          '--icons-color': this.iconsColor,
          '--items-tooltip-color': this.itemsTooltipColor,
          '--serach-input-text-color': this.searchInputTextColor,
          '--menu-items-hover-color': this.menuItemsHoverColor,
          '--menu-items-text-color': this.menuItemsTextColor,
          '--menu-footer-text-color': this.menuFooterTextColor,
        }
      },
      currentUser() {
      return this.$store.state.auth.user;
    },
    isSuperAdmin() {
      if (this.currentUser && this.currentUser.roles == 1) {
        return true;
      }

      return false;
    },
    isAdmin() {
      if (this.currentUser && this.currentUser.roles == 4) {
        return true;
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles == 2) {
        return true;
      }

      return false;
    },
    showUserBoard() {
      if (this.currentUser && this.currentUser.roles == 3) {
        return true;
      }

      return false;
    }
    },
    watch: {
      isOpened() {
        //window.document.body.style.paddingLeft = this.isOpened && this.isPaddingLeft ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody
      }
    }
  }
</script>

<style>
  /* Google Font Link */
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
  @import url('https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css');
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }
  body {
    transition: all 0.5s ease;
  }
  .menu-logo {
    width: 30px;
    margin: 0 10px 0 10px;
  }
  .sidebar {
    position: relative;
    display: flex;
    flex-direction: column;
    
    height: 100%;
    min-height: 100vh;
    /* overflow-y: auto; */
    width: 78px;
    background: var(--bg-color);
    /* padding: 6px 14px 0 14px; */
    z-index: 99;
    transition: all 0.5s ease;
    /* border-right: 2px solid #03f294;
    box-shadow: -13px 0px 26px -6px;
    */
  }
  .sidebar.open {
    width: 210px;
  }
  .sidebar .logo-details {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  .sidebar .logo-details .icon {
    opacity: 0;
    transition: all 0.2s ease;
  }
  .sidebar .logo-details .logo_name {
    color: var(--logo-title-color);
    font-size: 20px;
    font-weight: 600;
    opacity: 0;
    transition: all 0.2s ease;
  }
  .sidebar.open .logo-details .icon,
  .sidebar.open .logo-details .logo_name {
    opacity: 1;
  }
  .sidebar .logo-details #btn {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    transition: all 0.4s ease;
    font-size: 30px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .sidebar.open .logo-details #btn {
    text-align: left;
  }
  .sidebar i {
    color: #00b05a;
    height: 60px;
    min-width: 50px;
    font-size: 28px;
    text-align: center;
    line-height: 60px;
  }
  .sidebar .router-link-active i {
    color: #fff;
  }
  .sidebar .nav-list {
    margin-top: 20px;
    /* margin-bottom: 60px; */
    /* height: 100%; */
    /* min-height: min-content; */
  }
  .sidebar li {
    position: relative;
    margin: 8px 0;
    list-style: none;
  }
  .sidebar li .tooltip {
    position: fixed;
    /*top: -20px;*/
    left: 80px;
    z-index: 3;
    background: #f7f9f7;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    transition: 0s;
  }
  .sidebar li:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 100px;
    transform: translateY(-50%);
    
  }
  .sidebar .nav-list.admin li:hover .tooltip.solicitations {
    top: 130px;
  }
  .sidebar .nav-list.admin li:hover .tooltip.companies {
    top: 170px;
  }
  .sidebar .nav-list.admin li:hover .tooltip.sellers {
    top: 210px;
  }
  .sidebar .nav-list.admin li:hover .tooltip.financeiro {
    top: 250px;
  }
  .sidebar .nav-list.admin li:hover .tooltip.creditos {
    top: 290px;
  }
  .sidebar .nav-list.admin li:hover .tooltip.relatorios {
    top: 330px;
  }
  
  .sidebar .nav-list.admin.moderator li:hover .tooltip.chat {
    top: 150px;
  }
  .sidebar .nav-list.admin.moderator li:hover .tooltip.sellers {
    top: 190px;
  }
  .sidebar .nav-list.admin.moderator li:hover .tooltip.creditos {
    top: 230px;
  }
  .sidebar .nav-list.admin.moderator li:hover .tooltip.financeiro {
    top: 270px;
  }
  .sidebar .nav-list.admin.moderator li:hover .tooltip.relatorios {
    top: 310px;
  }

  .sidebar.open li .tooltip {
    display: none;
  }
  .sidebar input {
    font-size: 15px;
    color: var(--serach-input-text-color);
    font-weight: 400;
    outline: none;
    height: 50px;
    width: 100%;
    width: 50px;
    border: none;
    border-radius: 12px;
    transition: all 0.5s ease;
    background: var(--secondary-color);
  }
  .sidebar.open input {
    padding: 0 20px 0 50px;
    width: 100%;
  }
  .sidebar .bx::before {
    color:#e0b182;
  }
  .sidebar .bx-search {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 22px;
    background: var(--secondary-color);
    color: var(--icons-color);
  }
  .sidebar.open .bx-search:hover {
    background: var(--secondary-color);
    color: var(--icons-color);
  }
  .sidebar .bx-search:hover {
    background: var(--menu-items-hover-color);
    color: var(--bg-color);
  }
  .sidebar li .router-link-active {
    background: #e0b182;
  }
  .sidebar li .router-link-active .bx::before {
    color: #fff;
  }
  .sidebar li .router-link-active:hover .bx::before {
    color: #e0b182;
  }
  .sidebar li .router-link-active .links_name{
    color: #fff
  }
  .sidebar li a {
    display: flex;
    height: 35px;
    width: 100%;
    border-radius: 12px 0 0 12px;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease;
    background: var(--bg-color);
  }
  .sidebar li a:hover {
    background: var(--menu-items-hover-color);
  }
  .sidebar li a .links_name {
    color: #000;
    font-size: .875rem;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
  }
  .sidebar.open li a .links_name {
    opacity: 1;
    pointer-events: auto;
  }
  .sidebar li a:hover .links_name,
  .sidebar li a:hover i {
    transition: all 0.5s ease;
    color: #000;
    font-weight: 500
  }
  .sidebar li i {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    border-radius: 12px;
  }
  .sidebar div.profile {
    position: relative;
    height: 60px;
    width: 78px;
    /* left: 0;
    bottom: 0; */
    padding: 10px 14px;
    background: var(--secondary-color);
    transition: all 0.5s ease;
    overflow: hidden;
  }
  .sidebar.open div.profile {
    width: 210px;
  }
  .sidebar div .profile-details {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  .sidebar div img {
    width: 140px;
    object-fit: cover;
    border-radius: 6px;
    margin-right: 10px;
  }
  .sidebar div img.menu-iconlogo {
    width: 50px;
    margin-right: 10px;
    cursor:pointer
  }
  .sidebar div.profile .name,
  .sidebar div.profile .job {
    font-size: 15px;
    font-weight: 400;
    color: var(--menu-footer-text-color);
    white-space: nowrap;
  }
  .sidebar div.profile .job {
    font-size: 12px;
  }
  .sidebar .profile #log_out {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: var(--secondary-color);
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-radius: 0px;
    transition: all 0.5s ease;
  }
  .sidebar.open .profile #log_out {
    width: 50px;
    background: var(--secondary-color);
    opacity: 0;
  }
  .sidebar.open .profile:hover #log_out {
    opacity: 1;
  }
  .sidebar.open .profile #log_out:hover {
    opacity: 1;
    color: red;
  }
  .sidebar .profile #log_out:hover {
    color: red;
  }
  .home-section {
    position: relative;
    background: var(--home-section-color);
    min-height: 100vh;
    top: 0;
    left: 78px;
    width: calc(100% - 78px);
    transition: all 0.5s ease;
    z-index: 2;
  }
  .sidebar.open ~ .home-section {
    left: 210px;
    width: calc(100% - 210px);
  }
  .home-section .text {
    display: inline-block;
    color: var(--bg-color);
    font-size: 25px;
    font-weight: 500;
    margin: 18px;
  }
  .my-scroll-active {
    overflow-y: auto;
  }
  #my-scroll {
    overflow-y: auto;
    height: calc(100% - 60px);
  }
  #my-scroll::-webkit-scrollbar{
    display:none;
    /* background-color: rgba(255, 255, 255, 0.2); 
    width: 10px;
    border-radius:5px  */
  }
  /* #my-scroll::-webkit-scrollbar-thumb{
    background-color: red;
    border-radius:5px 
  }
  #my-scroll::-webkit-scrollbar-button:vertical:start:decrement{
    display:none;
  }
  #my-scroll::-webkit-scrollbar-button:vertical:end:increment{
    display:none;
  } */
  @media (max-width: 420px) {
    .sidebar li .tooltip {
      display: none;
    }
}


</style>